const IN_VIEWPORT_CLASS = 'product-card--in-viewport'

const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

const setInViewportCard = () => {
  const products = document.querySelectorAll('.js-product-card-datalayer')
  products.forEach(item => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        item.classList.add(IN_VIEWPORT_CLASS)
      } else {
        item.classList.remove(IN_VIEWPORT_CLASS)
      }
    }, {});
    observer.observe(item)
  })
}

export const scrollHandle = debounce(() => {
  setInViewportCard()
  handleListProductInViewport()
}, 200)

export const handleListProductInViewport = () => {
  const products = document.querySelectorAll('.product-card--in-viewport')
  let data = []
  products.forEach(item => {
    data.push(item.dataset)
  })

  const listId = data.map(item => item.productIndex)
  const sessionData = JSON.parse(sessionStorage.getItem('data-layer')) || []
  const sameIds = listId.length === sessionData.length && listId.every(function(value, index) { return value === sessionData[index]})
  if (!sameIds) {
    const viewItemList = []
    data.forEach(item => {
      const datalayerProduct = item.datalayerListProduct
      const productIndex = item.productIndex
      if (datalayerProduct) {
        const productData = JSON.parse(decodeURIComponent(datalayerProduct))
        if (productIndex) {
          productData.index = productIndex
        } else {
          productData.index = 0
        }

        productData.item_list_id = item.listId
        productData.item_list_name = item.listName

        viewItemList.push(getProductData(productData))
      }
    })

    pushViewItemListToDataLayer(viewItemList)
    sessionStorage.setItem('data-layer', JSON.stringify(listId))
  }
}

export function pushViewItemListToDataLayer (viewItemList) {
  if (!viewItemList || !viewItemList.length) return
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      items: viewItemList
    }
  });
}

setTimeout(() => {
  setInViewportCard()
  handleListProductInViewport()
}, 500)

document.addEventListener('scroll', () => {
  scrollHandle()
})

const getProductData = (product) => {
  const productData = {
    item_id: product.item_id || product.variants[0].sku,
    item_name: product.title.replace("'", ''),
    index: parseInt(product.index) || 0,
    item_brand: product.vendor,
    item_list_id: product.item_list_id || "featured_products",
    item_list_name: product.item_list_name || "Featured Products",
    item_variant: product.variants[0].title,
    price: (product.price / 1).toFixed(2).toString(),
    quantity: product.datalayer_quantity || 1,
    currency: window.BARREL.currency
  }

  const collections = product.collections
  if (collections && collections.length) {
    collections.forEach((cat, i) => {
      let index = i + 1
      if (index >= 5) return
      if (index === 1) {
        productData['item_category'] = cat.title
      } else {
        productData[`item_category${index}`] = cat.title
      }
    })
  }

  return productData
}

export const handleRemoveLineItem = (product) => {
  if (!product) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      items: [].concat(getProductData(product))
    }
  })
}

export const handleAddLineItem = (product) => {
  if (!product) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: [].concat(getProductData(product))
    }
  })
}